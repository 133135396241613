// Dependencies
import { useEffect } from 'react'
import { navigate } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default ({ location }) => {
  useEffect(() => {
    trackCustomEvent({
      category: '404',
      action: 'Access',
      label: location.pathname,
    })
    navigate('/')
  }, [])

  return null
}
